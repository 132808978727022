<template>
    <div>
        <LiefengContent>
            <template #title>{{ $route.query.columnName }}</template>
            <template #toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <Button style="margin-right: 10px" type="info" @click="exportExcel">导出报表</Button>
                </Form>
            </template>
            <template #contentArea>
                <div class="container">
                    <LiefengTable ref="lftableo" :tableData="tableData" :talbeColumns="talbeColumns" :loading="loading" :hidePage="true"></LiefengTable>
                </div>
            </template>
        </LiefengContent>
         <!-- 社区空白栏目 -->
            <LiefengModal :title="nameUrl" width="700px" height="70vh" :value="blankStatus" @input="changeBlankStatus">
                <template v-slot:title>
                    <p style="font-size: 18px; font-weight: 700">栏目数：{{ clumnNum }}</p>
                    <p>更新时间：{{ nowTime2 + " 00:00:00" || "" }}</p>
                    <Button type="primary" style="margin-left:20px" @click="exportColumn">导出</Button>
                </template>
                <template v-slot:contentarea>
                    <LiefengTable :tableData="bTableData" :talbeColumns="bTalbeColumns" :loading="bLoading" :hidePage="true"></LiefengTable>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="blankStatus = false">确定</Button>
                </template>
            </LiefengModal>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
    data() {
        return {
            tableData: [],
            talbeColumns: [
                {
                    title: "地址",
                    key: "orgFullName",
                    align: "center",
                },
                {
                    title: "社区",
                    key: "orgName",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: () => {
                                        this.blankStatus = true
                                        this.nameUrl = `广州市-${params.row.orgFullName}`
                                        this.getBlankList(params.row.orgCode)
                                    },
                                },
                            },
                            params.row.orgName
                        )
                    },
                },
                {
                    title: "路径",
                    key: "columnUrl",
                    align: "center",
                },
            ],
            loading: false,

            blankStatus: false,
            bTableData: [],
            bTalbeColumns: [
                {
                    title: "栏目路径",
                    key: "columnName",
                    minWidth: 400,
                    align: "center",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                    minWidth: 80,
                    align: "center",
                },
            ],
            bLoading: false,
            nameUrl: "",
            clumnNum: 0,
            nowTime2: "",
        }
    },
    methods: {
        changeBlankStatus(val) {
            this.blankStatus = val
        },
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = ["地址","社区", "路径"]
                    let filterVal = ["orgFullName","orgName", "columnUrl"]
                    let tableData = JSON.parse(JSON.stringify(this.tableData))
                    this.$core.exportExcel(tHeader, filterVal, tableData, `${this.$route.query.columnName || "栏目"}列表`)
                },
            })
        },
        exportColumn() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出社区栏目的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = ["栏目路径","栏目编码"]
                    let filterVal = ["columnName","columnCode"]
                    let tableData = JSON.parse(JSON.stringify(this.bTableData))
                    this.$core.exportExcel(tHeader, filterVal, tableData, `${this.nameUrl + '栏目' || "栏目"}列表`)
                },
            })
        },
        getList() {
            this.tableData = JSON.parse(this.$route.query.columnList)
        },
        // 获取空白栏目列表
        getBlankList(orgCode) {
            this.bLoading = true
            this.$get("/statistic/api/symanage/pc/columnTree/selectLayerByOrgCode", {
                orgCode,
            })
                .then(res => {
                    this.bLoading = false
                    if (res.code == 200 && res.dataList) {
                        this.nowTime2 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
                        this.bTableData = res.dataList.map((item, index) => {
                            return {
                                num: index + 1,
                                ...item,
                            }
                        })
                        this.bTableData = this.bTableData.filter(item =>{
                           if(item.columnCode && item.columnCode != '') return item
                        })
                        this.clumnNum = this.bTableData.length
                    } else {
                        this.nowTime2 = ""
                        this.$Message.error({
                            content: res.decs,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.nowTime2 = ""
                    this.bLoading = false
                    this.$Message.error({
                        content: "获取栏目失败",
                        background: true,
                    })
                })
        },
    },
    created() {
        this.getList()
    },
}
</script>

<style scoped lang="less">
/deep/#title {
    justify-content: space-between;
    display: flex;
    padding: 10px;
}
</style>